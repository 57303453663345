import React, { useEffect, useState } from 'react';
import { debounce } from '@/utils/debounce';
import LogoImage from '@/components/Header/LogoImage';
import NavMenu from '@/components/Header/NavMenu';
import { LoginButton, NavbarButton } from '@/components/Views/Buttons';
import { MUZOLOGY_APP } from '@/lib/defaults';
import { theme } from '@/theme/index';

export const NewNavbar = ({ currentPath, showClassCodeInput, showLoginAndSignup, signupLink, signupLinkText, hideNavigationMenu, stepUpNav, site }) => {
    const [scrollingNav, setScrollingNav] = useState(false);

    useEffect(() => {
        if (!process.browser) {
            return null;
        }
        const handleScroll = debounce(() => {
            setScrollingNav(true);
            // // find current scroll position
            const currentScrollPos = window.pageYOffset;
            if (currentScrollPos > 1) {
                setScrollingNav(true);
            } else {
                setScrollingNav(false);
            }
        }, 10);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const links = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/parents',
            label: 'Parents'
        },
        {
            link: '/teachers',
            label: 'Educators'
        },
        {
            link: '/about-us',
            label: 'About Us'
        }
    ];
    const clickableLogo = !stepUpNav;
    // const logoURL = '/assets/muzology-logo-tm.png';
    // const logoURL = '/assets/muzology-logo-9-23.png';
    const logoURL = '/assets/logo2.svg';
    // if (site && site.logo) {
    //     logoURL = site.logo.url;
    // }
    return (
        <nav data-comp={NewNavbar.displayName}>
            <div id='nav-content'>
                <div id='nav-left'>
                    <LogoImage url={logoURL} clickable={clickableLogo} />
                </div>

                <div id='nav-right' className='flex justify-end gap-4 xl:gap-9 '>
                    {/*{!hideNavigationMenu && (<NavMenu menuItems={links} currentPath={currentPath} />)}*/}
                    {showLoginAndSignup && (
                        <div className='flex justify-end gap-3 xl:gap-6'>
                            <NavbarButton
                                id='navbar-login-button'
                                className='navbar-button min-w-[87px] md:min-w-[200px] h-[39px] border-1 bg-[#F8DA57]'
                                // innerClassName='sm:whitespace-normal'
                                // innerClassName='whitespace-normal sm:whitespace-nowrap sm:text-xs sm:bg-red-300'
                                // innerClassName='sm:whitespace-normal'
                                // innerClassName='text-xs'
                    
                                text='Log In Here!'
                                href={MUZOLOGY_APP}
                                data-test='navbar-login-button'
                            />
                            {/*
                            <LoginButton
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    window.location.href = MUZOLOGY_APP;
                                }}
                                className='login-button lg:min-w-[80px]'
                                text='Log In'
                            />
                            */}
                        </div>
                    )}
                </div>
            </div>
            <style jsx>{`
                nav {
                    font-family: ${theme.font.primary};
                    position: fixed;
                    display: flex;
                    align-items: center;
                    height: 72px;

                    width: 100%;
                    top: 0;
                    z-index: 90;
                    padding: 0px 24px 0px 24px;
                    transition: all 100ms linear;
                    background-color: #271556;
                    box-shadow: ${scrollingNav ? 'rgb(25 45 100 / 8%) 0px 10px 30px 0px;' : 'none'};
                    @media print {
                        background-color: #fff;
                        box-shadow: none;
                    }

                    @media (max-width: 1280px) {
                        padding: 0 10px 0 10px;
                    }
                }

                #nav-content {
                    margin: 0 auto;
                    height: 100%;
                    width: 100%;
                    max-width: ${stepUpNav ? '' : ''};
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                }

                #nav-left {
                    display: flex;
                    justify-content: left;
                }

                #nav-center {
                    display: flex;
                    justify-content: center;
                    //margin: 0 44px 0 auto;
                    @media (max-width: 1280px) {
                        //margin: 0 0 0 auto;
                    }
                    @media print {
                        display: none;
                    }
                }

                #nav-right {
                    //background: blue;
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    flex-grow: 1;
                    @media print {
                        display: none;
                    }
                }

                #logo {
                    cursor: pointer;
                    //width: 171px;
                }

                #logo-stepup {
                    //width: 171px;
                }

                #login-btn {
                    cursor: pointer;
                    border: none;
                    font-size: 16px;
                    background: transparent;
                    width: 140px;
                    color: #646f79;

                    &:hover {
                        transition: color 200ms ease-in-out;
                        color: #333;
                    }
                }
            `}</style>
        </nav>
    );
};

NewNavbar.displayName = 'NewNavbar';

export default NewNavbar;
