import React from 'react';
import { ScaleLoader } from 'react-spinners';

const LoadingOverlay = ({ show = true, color = '#ffffff', className = '', style = {} }) => {
    if (!show) {
        return null;
    }

    return (
        <div className={`loading-overlay ${className}`} style={style}>
            <ScaleLoader color={color} />
            <p>Please wait...</p>
            <style jsx>
                {`
                    .loading-overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background: #000000dd;
                        z-index: 99999999;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        &.fixed {
                            position: fixed;
                            top: 0;
                            left: 0;
                            height: 100vh;
                            width: 100vw;
                        }

                        p {
                            color: white;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default LoadingOverlay;
