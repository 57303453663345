import React from 'react';
import Link from 'next/link';
import { Button } from 'atoms';
import { useDispatchUi } from 'context/ui';
import { useRouter } from 'next/router';
import LargeButton from '@/components/Views/Buttons';
import MobileSignUpChildButton from './MobileSignUpChildButton';
import { MUZOLOGY_APP } from '@/lib/defaults';

export const MobileNavDrawer = ({ currentPath }) => {
    const links = [
        {
            label: 'Home',
            link: '/'
        },
        {
            label: 'About Us',
            link: '/about-us'
        },
        {
            label: 'Learn More',
            link: '/learn-more'
        },
        {
            label: 'Research',
            link: '/research'
        },
        {
            label: 'Press',
            link: '/press'
        },
        {
            label: 'FAQ',
            link: '/faq'
        }
    ];
    return (
        <div id='mobile-nav-drawer'>
            {links.map((link, index) => (
                <Link href={link.link} key={index} passHref>
                    <a className={`link ${currentPath === link.link ? 'selected-item' : ''}`}>{link.label}</a>
                </Link>
            ))}

            <div className='flex flex-col md:flex-row justify-items-stretch -stretch gap-4'>
                {/*<div className='grid gap-4 grid-cols-1 sm:grid-cols-2'>*/}
                {/* <LargeButton href='/checkout' className='purple-button h-[50px] w-full' text='Sign Up My Child' /> */}
            
                <LargeButton href='/signup' className='blue-button h-[50px] w-full' text='Sign Up As Educator' />
                <LargeButton href={MUZOLOGY_APP} className='white-button h-[50px] w-full' text='Log In' />
                {/* <LargeButton href={`${MUZOLOGY_APP}/join`} className='md:hidden orange-button h-[50px] w-full' text='Sign Up With Class Join Code' /> */}
            </div>
            <style jsx>{`
                #mobile-nav-drawer {
                    z-index: 2;
                    background-color: #fff;
                    position: fixed;
                    top: 76px;
                    left: 0;
                    right: 0;
                    //right: -17px; // hides scrollbar offscreen
                    bottom: 0;
                    padding: 32px 60px;
                    overflow-y: scroll;
                    animation: slidein 150ms ease-out;
                }

                .link {
                    font-family: Inter, sans-serif;

                    display: block;
                    margin-bottom: 24px;
                    text-decoration: none;
                    color: #4f4f4f;
                    font-size: 24px;
                    cursor: pointer;
                    position: relative;
                    width: fit-content;
                    padding: 0 10px 0 0;

                    &:hover {
                        transition: color 200ms linear;
                        color: #666;
                    }

                    &.selected-item {
                        color: #121651;
                        text-decoration: underline;

                    }
                }

                @keyframes slidein {
                    0% {
                        transform: translateX(calc(100%));
                    }
                    100% {
                        transform: translateX(0px);
                    }
                }

                @media (max-width: 992px) {
                    #mobile-nav-drawer {
                        padding: 24px 40px;
                    }
                }

                @media (max-width: 480px) {
                    #mobile-nav-drawer {
                        padding: 16px 20px;
                    }
                }
            `}</style>
        </div>
    );
};
